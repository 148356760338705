var datalist = [];
let data = [];
let edittemp = {
    path: '/documents/',
    name: 'documents',
    component: () => import('@/views/documents/List.vue'),
}

data.push(edittemp)
var datalist = [{
        title: 'Aadhar Card',
        name: 'documents-aadharcard',
        path: '/documents/aadharcard',
        filePath: '/documents/aadharcard',
    },
    {
        title: 'Will',
        name: 'documents-will',
        path: '/documents/will',
        filePath: '/documents/will',
    },
    {
        title: 'Guarantor',
        name: 'documents-guarantor',
        path: '/documents/guarantor',
        filePath: '/documents/guarantor',
    },

    {
        title: 'Certificates',
        name: 'documents-certificates',
        path: '/documents/certificates',
        filePath: '/documents/certificates',
    },
    {
        title: 'POA',
        name: 'documents-poa',
        path: '/documents/poa',
        filePath: '/documents/poa',
    },
    {
        title: 'Other Documents',
        name: 'documents-otherdocuments',
        path: '/documents/otherdocuments',
        filePath: '/documents/otherdocuments',
    },
    {
        title: 'Ration Card',
        name: 'documents-rationcard',
        path: '/documents/rationcard',
        filePath: '/documents/rationcard',
    },
    {
        title: 'Driving License',
        name: 'documents-drivinglicense',
        path: '/documents/drivinglicense',
        filePath: '/documents/drivinglicense',
    },
    {
        title: 'Voter ID',
        name: 'documents-voterid',
        path: '/documents/voterid',
        filePath: '/documents/voterid',

    },
    {
        title: 'PAN Card',
        name: 'documents-pancard',
        path: '/documents/pancard',
        filePath: '/documents/pancard',
    },
    {
        title: ' Marriage Certificate ',
        name: 'documents-marriagecertificate',
        path: '/documents/marriagecertificate',
        filePath: '/documents/marriagecertificate',
    },
    {
        title: 'Domicile Certificate',
        name: 'documents-domicilecertificate',
        path: '/documents/domicilecertificate',
        filePath: '/documents/domicilecertificate',
    },
    {
        title: 'Birth Certificate',
        name: 'documents-birthcertificate',
        path: '/documents/birthcertificate',
        filePath: '/documents/birthcertificate',
    },
    {
        title: 'Passport',
        name: 'documents-passport',
        path: '/documents/passport',
        filePath: '/documents/passport',
    },
    {
        title: 'Education Certificate',
        name: 'documents-educationcertificate',
        path: '/documents/educationcertificate',
        filePath: '/documents/educationcertificate',
    },
    {
        title: 'Property Documents',
        name: 'documents-propertydocuments',
        path: '/documents/propertydocuments',
        filePath: '/documents/propertydocuments',
    },
];
for (let i = 0; i < datalist.length; i++) {
    let listtemp = {
        path: datalist[i].path,
        name: datalist[i].name,
        component: () => import('@/views' + datalist[i].filePath + '/List.vue'),
        meta: {
            pageTitle:datalist[i].title,
            breadcrumb: [{
                    text: 'Documents',
                    to: '/documentsvault/documents',
                },
                {
                    text: datalist[i].title,
                    active: true,
                },
            ]
        },

    }
    data.push(listtemp);
    let addtemp = {
        path: datalist[i].path + '/add',
        name: datalist[i].name + '-add',
        component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
        meta: {
            pageTitle:datalist[i].title,
            breadcrumb: [{
                text: 'Documents',
                to: '/documentsvault/documents',
            }, {
                text: datalist[i].title,
                to: datalist[i].path,
            }, {
                text: 'Add',
                active: true,
            }, ],
        },
    }
    data.push(addtemp);
    let edittemp = {
        path: datalist[i].path + '/edit/:id',
        name: datalist[i].name + '-edit',
        component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
        meta: {
            pageTitle:datalist[i].title,
            breadcrumb: [{
                    text: 'Documents',
                    to: '/documentsvault/documents',
                }, {
                    text: datalist[i].title,
                    to: datalist[i].path,
                },  {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    }
    data.push(edittemp);
    let viewtemp = {
        path: datalist[i].path + '/view/:id',
        name: datalist[i].name + '-view',
        component: () => import('@/views' + datalist[i].filePath + '/View.vue'),
        meta: {
            pageTitle:datalist[i].title,
            breadcrumb: [{
                    text: 'Documents',
                    to: '/documentsvault/documents',
                }, {
                    text: datalist[i].title,
                    to: datalist[i].path,
                }, {
                    text: 'View',
                    active: true,
                },
            ],
        },
    }
    data.push(viewtemp);
}
export default data
