var datalist = [{
        title: 'navigation.travel',
        name: 'leisure-travel',
        path: '/leisure/travel',
        filePath: '/leisure/vacations',
    },
    {
        title: 'navigation.subscriptions',
        name: 'leisure-subscriptions',
        path: '/leisure/subscriptions',
        filePath: '/leisure/subscriptions',
    },
    {
        title: 'navigation.occasion',
        name: 'leisure-occasion',
        path: '/leisure/occasion',
        filePath: '/leisure/occasion',
    },
    {
        title: 'navigation.membership',
        name: 'leisure-membership',
        path: '/leisure/membership',
        filePath: '/leisure/membership',
    },

];


let data = [];
for (let i = 0; i < datalist.length; i++) {
    let listtemp = {
        path: datalist[i].path,
        name: datalist[i].name,
        component: () => import('@/views' + datalist[i].filePath + '/List.vue'),
        meta: {
            pageTitle: 'navigation.leisure',
            breadcrumb: [{
                    text: 'navigation.leisure',
                    to: '/leisure/subscriptions',
                },
                {
                    text: datalist[i].title,
                    active: true,
                },
            ],
        },
    }
    data.push(listtemp);
    let addtemp = {
        path: datalist[i].path + '/add',
        name: datalist[i].name + '-add',
        component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
        meta: {
            pageTitle: 'navigation.leisure',
            breadcrumb: [{
                    text: 'navigation.leisure',
                    to: '/leisure',
                },
                {
                    text: datalist[i].title,
                    to: datalist[i].path,
                }, {
                    text: 'Add',
                    active: true,
                },
            ],
        },
    }
    data.push(addtemp);
    let edittemp = {
        path: datalist[i].path + '/edit/:id',
        name: datalist[i].name + '-edit',
        component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
        meta: {
            pageTitle: 'navigation.leisure',
            breadcrumb: [{
                    text: 'navigation.leisure',
                    to: '/leisure',
                },
                {
                    text: datalist[i].title,
                    to: datalist[i].path,
                }, {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    }
    data.push(edittemp);
    let viewtemp = {
        path: datalist[i].path + '/view/:id',
        name: datalist[i].name + '-view',
        component: () => import('@/views' + datalist[i].filePath + '/View.vue'),
        meta: {
            pageTitle: 'navigation.leisure',
            breadcrumb: [{
                    text: 'navigation.leisure',
                    to: '/leisure',
                },
                {
                    text: datalist[i].title,
                    to: datalist[i].path,
                }, {
                    text: 'View',
                    active: true,
                },
            ],
        },
    }
    data.push(viewtemp);


}
export default data
