var datalist = [

    {
        title: 'Precious Metals',
        name: 'physicalassets-preciousmetals',
        path: '/physicalassets/preciousmetals',
        filePath: '/assets/bullion',
    },
    {
        title: 'Household Equipments',
        name: 'physicalassets-householdequipments',
        path: '/physicalassets/householdequipments',
        filePath: '/assets/householdequipments',
    },
    {
        title: 'AutoMobile',
        name: 'physicalassets-automobile',
        path: '/physicalassets/automobile',
        filePath: '/assets/automobile',
    },
    {
        title: 'Arts and Collectible ',
        name: 'physicalassets-artsandcollectible',
        path: '/physicalassets/artsandcollectible',
        filePath: '/assets/artsandcollectible',
    },
    {
        title: 'Locker',
        name: 'physicalassets-locker',
        path: '/physicalassets/locker',
        filePath: '/assets/locker',
    },
    {
        title: 'navigation.realstate',
        name: 'physicalassets-realestate',
        path: '/physicalassets/realestate',
        filePath: '/assets/realestate',
    },
];
let data = [];
for (let i = 0; i < datalist.length; i++) {
    let listtemp = {
        path: datalist[i].path,
        name: datalist[i].name,
        component: () => import('@/views' + datalist[i].filePath + '/List.vue'),
        meta: {
            pageTitle: 'navigation.physicalassets',
            breadcrumb: [
                {
                    text: 'navigation.physicalassets',
                    to: '/physicalassets/realestate',
                },
                {
                    text: datalist[i].title,
                    active: true,
                },
            ],
        },
    }
    data.push(listtemp);
    let addtemp = {
        path: datalist[i].path + '/add',
        name: datalist[i].name + '-add',
        component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
        meta: {
            pageTitle: 'navigation.physicalassets',
            breadcrumb: [
                {
                    text: 'navigation.physicalassets',
                    to: '/physicalassets',
                },
                {
                    text: datalist[i].title,
                    to: datalist[i].path,
                },
                {
                    text: 'Add',
                    active: true,
                },
            ],
        },
    }
    data.push(addtemp);
    let edittemp = {
        path: datalist[i].path + '/edit/:id',
        name: datalist[i].name + '-edit',
        component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
        meta: {
            pageTitle: 'navigation.physicalassets',
            breadcrumb: [{
                text: 'navigation.physicalassets',
                to: '/physicalassets',
            },
            {
                text: datalist[i].title,
                to: datalist[i].path,
            },{
                text: 'Edit',
                active: true,
            }, ],
        },
    }
    data.push(edittemp);
    let viewtemp = {
        path: datalist[i].path + '/view/:id',
        name: datalist[i].name + '-view',
        component: () => import('@/views' + datalist[i].filePath + '/View.vue'),
        meta: {
            pageTitle: 'navigation.physicalassets',
            breadcrumb: [{
                text: 'navigation.physicalassets',
                to: '/physicalassets',
            },
            {
                text: datalist[i].title,
                to: datalist[i].path,
            },{
                text: 'View',
                active: true,
            }, ],
        },
    }
    data.push(viewtemp);


}
export default data
