import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import {
    canNavigate
} from '@/libs/acl/routeProtection'
import {
    isUserLoggedIn,
    getUserData,
    getHomeRouteForLoggedInUser
} from '@/auth/utils'
import pages from './routes/pages'
import settings from './routes/settings'
import finance from './routes/finance'
import insurance from './routes/insurance'
import assets from './routes/assets'
import assistance from './routes/assistance'
import leisure from './routes/leisure'
import utilities from './routes/utilities'
import documents from './routes/documents'
import budget from './routes/budget'
import children from './routes/children'
import reports from './routes/reports'
import documentsvault from './routes/docvault'
import liabilities from './routes/liabilities'
import health from './routes/health'
import cashflow from './routes/cashflow'
import policies from './routes/policies'
import employment from './routes/employment'

// import alerts from './routes/alerts'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    },
    routes: [
          {
            path: '/overview/',
            name: 'dashboard',
            component: () => import('@/views/dashboard/ecommerce/dashboard.vue'),
          },
          {
            path: '/',
            redirect: 'overview',
          },

        // App

        // Personal
        ...pages,
        ...settings,
        ...finance,
        ...insurance,
        ...assets,
        ...assistance,
        ...leisure,
        ...utilities,
        ...documents,
        ...budget,
        ...children,
        ...reports,
        ...liabilities,
        ...documentsvault,
        ...health,
        ...cashflow,
        ...policies,
        ...employment,
        {
            path: '*',
            redirect: 'error-404',
        },



    ],
})


router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn()
    const userData = getUserData()

    if (userData && userData.email_verified_at == null &&
        !['/otpverification', '/plans'].includes(to.fullPath)) {
        return next({
            name: 'auth-otp'
        })
    }
    if (userData && userData.pricing == null &&
        !['/otpverification', '/plans'].includes(to.fullPath)) {
        return next({
            name: 'auth-plans'
        })
    }


    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }


    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
