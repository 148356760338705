var datalist = [{
    title: 'navigation.importantcontacts',
    name: 'assistance-importantcontacts',
    path: '/assistance/importantcontacts',
    filePath: '/assistance/importantcontacts',
  },
  {
    title: 'navigation.supportstaff',
    name: 'assistance-supportstaff',
    path: '/assistance/supportstaff',
    filePath: '/assistance/supportstaff',
  },
  {
    title: 'navigation.helplinenumber',
    name: 'assistance-helplinenumber',
    path: '/assistance/helplinenumber',
    filePath: '/assistance/helplinenumber',
  },
];
let data = [];
for (let i = 0; i < datalist.length; i++) {
  let listtemp = {
    path: datalist[i].path,
    name: datalist[i].name,
    component: () => import('@/views' + datalist[i].filePath + '/List.vue'),
    meta: {
      pageTitle: 'navigation.assistance',
      breadcrumb: [
          {
              text: 'navigation.assistance',
              to: '/assistance/importantcontacts',
          },
          {
              text: datalist[i].title,
              active: true,
          },
      ],
    },
  }
  data.push(listtemp);
  let addtemp = {
    path: datalist[i].path + '/add',
    name: datalist[i].name + '-add',
    component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
    meta: {
      pageTitle: 'navigation.assistance',
      breadcrumb: [
        {
            text: 'navigation.assistance',
            to: '/assistance',
        },
        {
            text: datalist[i].title,
            to: datalist[i].path,
        },{
        text: 'Add',
        active: true,
      }, ],
    },
  }
  data.push(addtemp);
  let edittemp = {
    path: datalist[i].path + '/edit/:id',
    name: datalist[i].name + '-edit',
    component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
    meta: {
      pageTitle: 'navigation.assistance',
      breadcrumb: [
        {
            text: 'navigation.assistance',
            to: '/assistance',
        },
        {
            text: datalist[i].title,
            to: datalist[i].path,
        },{
        text: 'Edit',
        active: true,
      }, ],
    },
  }
  data.push(edittemp);
  let viewtemp = {
    path: datalist[i].path + '/view/:id',
    name: datalist[i].name + '-view',
    component: () => import('@/views' + datalist[i].filePath + '/View.vue'),
    meta: {
      pageTitle: 'navigation.assistance',
      breadcrumb: [
        {
            text: 'navigation.assistance',
            to: '/assistance',
        },
        {
            text: datalist[i].title,
            to: datalist[i].path,
        },{
        text: 'View',
        active: true,
      }, ],
    },
  }
  data.push(viewtemp);
}
export default data
