import Vue from 'vue'

// axios
import axios from 'axios'
import {
  initialAbility
} from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import router from "@/router";


let userData = JSON.parse(localStorage.getItem('userData'))
const axiosIns = axios.create({
  // You can add your headers here
  // ================================

  //baseURL: 'https://userfrp.smartfrp.com/api/',
  baseURL: 'https://userfrp.smartfrp.com/api/',
  // httppp://frpuser2.com
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
    localStorage.removeItem('userData')
    router.push({
      name: 'auth-login'
    })
  }
  if (503 === error.response.status) {
    router.push({
      name: 'auth-otp'
    })
  }
  return Promise.reject(error);
});
Vue.prototype.$http = axiosIns

export default axiosIns



// baseURL: 'Hattps://userfrp.smartfrp.com/api/', User Domain
// lOCAL SERVER: 'https://userfrp.smartfrp.com/api/', LOCAL Domain
