var datalist = [{
        title: 'Physical Assets',
        name: 'physicalassets',
        children: [{
                title: 'Real Estate',
                name: 'realestate',
            },
            {
                title: 'Household Equipments',
                name: 'householdequipments',
            },
            {
                title: 'Automobile',
                name: 'automobile',
            },
            {
                title: 'Precious Metals',
                name: 'preciousmetals',
            },
            {
                title: 'Arts & Collectible',
                name: 'artsandcollectible',
            },
            {
                title: 'Locker',
                name: 'locker',
            },
        ]
    },
    {
        title: 'Financial Assets',
        name: 'financialassets',
        children: [{
                title: 'Bank',
                name: 'bank',
            },
            {
                title: 'Wallet',
                name: 'wallet',
            },
            {
                title: 'Cash',
                name: 'cash',
            },
            {
                title: 'Retirals',
                name: 'retirals',
            },

            {
                title: 'Receivables',
                name: 'receivables',
            },
            {
                title: 'SSY',
                name: 'ssy',
            },
            {
                title: 'Fixed Deposits',
                name: 'fixeddeposits',
            },
                {
                title: 'Recurring Deposits',
                name: 'recurringdeposits',
            },
            {
                title: 'Mutual Funds',
                name: 'mutualfunds',
            },
            {
                title: 'Crypto',
                name: 'crypto',
            },
            {
                title: 'Securities',
                name: 'securities',
            },
            {
                title: 'Life Insurance',
                name: 'lifeinsurance',
            },
            {
                title: 'NSC',
                name: 'nsc',
            },
        ]
    },
    {
        title: 'Assistance',
        name: 'assistance',
        children: [{
                title: 'Important Contacts',
                name: 'importantcontacts',
            },
            {
                title: 'Support Staff',
                name: 'supportstaff',
            },
            {
                title: 'Helpline Number',
                name: 'helplinenumber',
            },


        ]
    },
    {
        title: 'Cashflow',
        name: 'cashflow',
        children: [

            {
                title: 'Transaction',
                name: 'transaction',
            },
            {
                title: 'transfer',
                name: 'transfer',
            },

        ]
    },
    {
        title: 'Liabilities',
        name: 'liabilities',
        children: [{
                title: 'Loan',
                name: 'loan',
            },
            {
                title: 'Credit Cards',
                name: 'creditcards',
            },
            {
                title: 'Pay Later',
                name: 'paylater',
            },
            {
                title: 'Payables',
                name: 'payables',
            },
        ]
    },
    {
        title: 'Utilities',
        name: 'utilities',
        children: [{
                title: 'Water',
                name: 'water',
            },
            {
                title: 'Electricity',
                name: 'electricity',
            },
            {
                title: 'CableTV/DTH',
                name: 'cabletvdth',
            },
            {
                title: 'Mobile',
                name: 'mobile',
            },
            {
                title: 'Landline/Broadband',
                name: 'landbroad',
            },
            {
                title: 'Gas',
                name: 'gas',
            },

        ]
    },
    {
        title: 'Leisure',
        name: 'leisure',
        children: [{
                title: 'Subscriptions',
                name: 'subscriptions',
            },
            {
                title: 'Occasion',
                name: 'occasion',
            },
            {
                title: 'Membership',
                name: 'membership',
            },
            {
                title: 'Travel',
                name: 'travel',
            },

        ]
    },
    {
        title: 'Children',
        name: 'children',
        children: [{
                title: 'School',
                name: 'school',
            },
            {
                title: 'Vaccination',
                name: 'vaccination',
            },
        ]
    },

    // {
    //     title: 'Investment',
    //     name: 'investment',
    //     children: [{
    //             title: 'Retirals',
    //             name: 'retirals',
    //         },
    //         {
    //             title: 'Fixed Deposits',
    //             name: 'fixeddeposits',
    //         },
    //         {
    //             title: 'Sukanya Samriddhi Yojana',
    //             name: 'ssy',
    //         },

    //         {
    //             title: 'Mutual Funds',
    //             name: 'mutualfunds',
    //         },
    //         {
    //             title: 'Securities',
    //             name: 'securities',
    //         },
    //         {
    //             title: 'NSC',
    //             name: 'nsc',
    //         },
    //         {
    //             title: 'Recurring Deposits',
    //             name: 'recurringdeposits',
    //         },
    //     ]
    // },
    {
        title: 'Health',
        name: 'health',
        children: [{
                title: 'Insurance',
                name: 'insurance',
            },
            {
                title: 'OBD',
                name: 'opd',
            },
            {
                title: 'Cards',
                name: 'cards',
            },
              {
                title: 'General Vaccination',
                name: 'generalvaccination',
            },
        ]
    },
    {
        title: 'Insurance',
        name: 'insurance',
        children: [{
                title: 'Life',
                name: 'life',
            },
            {
                title: 'General',
                name: 'general',
            },
        ]
    },
    {
        title: 'Other Key Documents',
        name: 'documents',
        children: [

            {
                title: 'Will',
                name: 'will',
            },
            {
                title: 'POA',
                name: 'poa',
            },
            {
                title: 'Aaadhar Card',
                name: 'aadharcard',
            },
            {
                title: 'Guarantor',
                name: 'guarantor',
            },

            {
                title: 'Certificates',
                name: 'certificates',
            },
            {
                title: 'Will',
                name: 'will',
            },
            {
                title: 'Other Documents',
                name: 'otherdocuments',
            },
            {
                title: 'Ration Card',
                name: 'rationcard',
            },
            {
                title: 'PAN Card',
                name: 'pancard',
            },
            {
                title: 'Marriage Certificate',
                name: 'marriagecertificate',
            },
            {
                title: 'Domicile Certificate',
                name: 'domicilecertificate',
            },
            {
                title: 'Birth Certificate',
                name: 'birthcertificate',
            },
            {
                title: 'Voter ID',
                name: 'voterid',
            },
              {
                title: 'Passport',
                name: 'passport',
            },
            {
                title: 'Driving License',
                name: 'drivinglicense',
            },
                {
                title: 'Education Certificate',
                name: 'educationcertificate',
            },
            {
                title: 'Property Documents',
                name: 'propertydocuments',
            },

        ]
    },
    {
        title: 'Employment',
        name: 'employment',
        children: [

            {
                title: 'Salary Slips',
                name: 'salaryslips',
            },
            {
                title: 'Offer Letters',
                name: 'offerletters',
            },
            {
                title: 'Relieving Letters',
                name: 'relievingletters',
            },
            {
                title: 'Form 16',
                name: 'form',
            },
            {
                title: 'Other Documents',
                name: 'otherdocuments',
            },
        ]
    },

];

let data = [];
let listtemp = {
    path: '/documentsvault',
    name: 'documentsvault',
    component: () => import('@/views/documentsvault/List.vue'),
    meta: {
        pageTitle: 'navigation.documentsvault',

        breadcrumb: [{
            text: 'Documents Vault',
            active: true,
        }, ],
    },
}
data.push(listtemp);
datalist.forEach(element => {
    let listtemp = {
        path: '/documentsvault/' + element.name,
        name: 'documentsvault-' + element.name,
        component: () => import('@/views/documentsvault/' + element.name + '.vue'),
        meta: {
            pageTitle: 'navigation.' + element.name,
            breadcrumb: [{
                    text: 'Documents Vault',
                    to: '/documentsvault',
                },
                {
                    text: element.title,
                    active: true,
                }
            ],
        },
    }
    data.push(listtemp);
    element.children.forEach(elementx => {
        let listtemp = {
            path: '/documentsvault/' + element.name + '/' + elementx.name,
            name: 'documentsvault-' + element.name + '-' + elementx.name,
            component: () => import('@/views/documentsvault/' + element.name + '/' + elementx.name + '/List.vue'),
            meta: {
                pageTitle: elementx.title,
                breadcrumb: [{
                        text: 'Documents Vault',
                        to: '/documentsvault',
                    },
                    {
                        text: element.title,
                        to: '/documentsvault/' + element.name,
                    },
                    {
                        text: elementx.title,
                        active: true,
                    },
                ],
            },
        }
        data.push(listtemp);
    });
});

//////////////////////////
export default data
