var datalist = [
    {
      title: 'settings.setting.termsandcond',
      name: 'settings-policies-termsandcond',
      path: '/settings/policies/termsandcond',
      filePath: '/settings/policies/termsandcond.vue',
    },
    {
      title: 'settings.setting.privacy',
      name: 'settings-policies-usermannual',
      path: '/settings/policies/usermannual',
      filePath: '/settings/policies/usermannual.vue',
    },
    {
      title: 'settings.setting.privacy',
      name: 'settings-policies-privacy',
      path: '/settings/policies/privacy',
      filePath: '/settings/policies/privacy.vue',
    },
    {
      title: 'settings.setting.subscription',
      name: 'settings-policies-subscription',
      path: '/settings/subscription',
      filePath: '/settings/policies/subscription.vue',
    },
];


let data = [];
for (let i = 0; i < datalist.length; i++) {
  let listtemp = {
    path: datalist[i].path,
    name: datalist[i].name,
    component: () => import('@/views' + datalist[i].filePath),
    meta: {
        pageTitle: 'navigation.settings',
      },
  }
  data.push(listtemp);
}
export default data
