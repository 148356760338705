var datalist = [{
    title: 'Cash',
     name: 'financialassets-cash',
    path: '/financialassets/cash',
    filePath: '/finance/cash',
  },
  {
    title: 'Receivables',
     name: 'financialassets-receivables',
    path: '/financialassets/receivables',
    filePath: '/finance/receivables',
  },
{
    title: 'Crypto',
     name: 'financialassets-crypto',
    path: '/financialassets/crypto',
    filePath: '/finance/crypto',
  },
  {
    title: 'Bank',
     name: 'financialassets-bank',
    path: '/financialassets/bank',
    filePath: '/finance/bank',
  },
  {
    title: 'SSY',
    name: 'financialassets-ssy',
    path: '/financialassets/ssy',
    filePath: '/finance/ssy',
  },
  {
    title: 'Wallet',
     name: 'financialassets-wallet',
    path: '/financialassets/wallet',
    filePath: '/finance/wallet',
  },
{
    title: 'Retirals',
     name: 'financialassets-retirals',
    path: '/financialassets/retirals',
    filePath: '/finance/retirals',
  },
  {
    title: 'Fixed Deposits',
     name: 'financialassets-fixeddeposits',
    path: '/financialassets/fixeddeposits',
    filePath: '/finance/fixeddeposits',
  },
  {
    title: 'Recurring Deposits',
     name: 'financialassets-recurringdeposits',
    path: '/financialassets/recurringdeposits',
    filePath: '/finance/recurringdeposits',
  },
  {
    title: 'Mutual Funds/SIP',
    name: 'financialassets-mutualfunds',
    path: '/financialassets/mutualfunds',
    filePath: '/finance/mutualfunds',
},
{
    title: 'Securities',
    name: 'financialassets-securities',
    path: '/financialassets/securities',
    filePath: '/finance/securities',
},
{
    title: 'Life Insurance',
    name: 'financialassets-lifeinsurance',
    path: '/financialassets/lifeinsurance',
    filePath: '/finance/lifeinsurance',
},
{
    title: 'NSC',
    name: 'financialassets-nsc',
    path: '/financialassets/nsc',
    filePath: '/finance/nsc',
},
];
let data = [];
for (let i = 0; i < datalist.length; i++) {
  let listtemp = {
    path: datalist[i].path,
    name: datalist[i].name,
    component: () => import('@/views' + datalist[i].filePath + '/List.vue'),
    meta: {
      pageTitle: 'navigation.financialassets',
      breadcrumb: [
          {
              text: 'navigation.financialassets',
              to: '/financialassets/cash',
          },
          {
              text: datalist[i].title,
              active: true,
          },
      ],
    },
  }
  data.push(listtemp);
  let addtemp = {
    path: datalist[i].path + '/add',
    name: datalist[i].name + '-add',
    component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
    meta: {
      pageTitle: 'navigation.financialassets',
      breadcrumb: [
        {
            text: 'navigation.financialassets',
            to: '/finance',
        },
        {
            text: datalist[i].title,
            to: datalist[i].path,
        },{
        text: 'Add',
        active: true,
      }, ],
    },
  }
  data.push(addtemp);
  let edittemp = {
    path: datalist[i].path + '/edit/:id',
    name: datalist[i].name + '-edit',
    component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
    meta: {
      pageTitle: 'navigation.financialassets',
      breadcrumb: [
        {
            text: 'navigation.financialassets',
            to: '/finance',
        },
        {
            text: datalist[i].title,
            to: datalist[i].path,
        },{
        text: 'Edit',
        active: true,
      }, ],
    },
  }
  data.push(edittemp);
  let viewtemp = {
    path: datalist[i].path + '/view/:id',
    name: datalist[i].name + '-view',
    component: () => import('@/views' + datalist[i].filePath + '/View.vue'),
    meta: {
      pageTitle: 'navigation.financialassets',
      breadcrumb: [
        {
            text: 'navigation.financialassets',
            to: '/finance',
        },
        {
            text: datalist[i].title,
            to: datalist[i].path,
        },{
        text: 'View',
        active: true,
      }, ],
    },
  }
  data.push(viewtemp);
}
export default data
