var datalist = [{
    title: 'navigation.budget',
    name: 'budget',
    path: '/budget',
    filePath: '/budget',
  },

];

let data = [];
for (let i = 0; i < datalist.length; i++) {
  let listtemp = {
    path: datalist[i].path,
    name: datalist[i].name,
    component: () => import('@/views' + datalist[i].filePath + '/List.vue'),
    meta: {
      pageTitle: 'navigation.budget',
    },
  }
  data.push(listtemp);
  let addtemp = {
    path: datalist[i].path + '/add',
    name: datalist[i].name + '-add',
    component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
    meta: {
      pageTitle: 'navigation.budget',
      breadcrumb: [{
        text: 'Add',
        active: true,
      }, ],
    },
  }
  data.push(addtemp);
  let edittemp = {
    path: datalist[i].path + '/edit/:id',
    name: datalist[i].name + '-edit',
    component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
    meta: {
      pageTitle: 'navigation.budget',
      breadcrumb: [{
        text: 'Edit',
        active: true,
      }, ],
    },
  }
  data.push(edittemp);
  let viewtemp = {
    path: datalist[i].path + '/view/:id',
    name: datalist[i].name + '-view',
    component: () => import('@/views' + datalist[i].filePath + '/View.vue'),
    meta: {
      pageTitle: 'navigation.budget',
      breadcrumb: [{
        text: 'View',
        active: true,
      }, ],
    },
  }
  data.push(viewtemp);


}
export default data
