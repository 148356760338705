var datalist = [{
        title: 'Physical Assets',
        name: 'physicalassets',
        children: [{
                title: 'Real Estate',
                name: 'realestate',
            },
            {
                title: 'Household Equipments',
                name: 'householdequipments',
            },
            {
                title: 'Automobile',
                name: 'automobile',
            },
            {
                title: 'Precious Metals',
                name: 'preciousmetals',
            },
            {
                title: 'Arts & Collectible',
                name: 'artsandcollectible',
            },
            {
                title: 'Locker',
                name: 'locker',
            }
        ]
    },
    {
        title: 'Financial Assets',
        name: 'financialassets',
        children: [{
                title: 'Bank',
                name: 'bank',
            },
            {
                title: 'Wallet',
                name: 'wallet',
            },
            {
                title: 'Cash',
                name: 'cash',
            },

            {
                title: 'Retirals',
                name: 'retirals',
            },
            {
                title: 'Others',
                name: 'others',
            },
            {
                title: 'Fixed Deposits',
                name: 'fixeddeposits',
            },
            {
                title: 'SSY',
                name: 'ssy',
            },
            {
                title: 'Receivables',
                name: 'receivables',
            },
            {
                title: 'Recurring Deposits',
                name: 'recurringdeposits',
            },
            {
                title: 'Mutual Funds',
                name: 'mutualfunds',
            },
            {
                title: 'Securities',
                name: 'securities',
            },
            {
                title: 'NSC',
                name: 'nsc',
            },
            {
                title: 'Crypto',
                name: 'crypto',
            },
            {
                title: 'Life Insurance',
                name: 'lifeinsurance',
            },

        ]
    },
    {
        title: 'Assistance',
        name: 'assistance',
        children: [
            {
                title: 'Important Contacts',
                name: 'importantcontacts',
            },
            {
                title: 'support staff',
                name: 'supportstaff',
            },
            // {
            //     title: 'Health',
            //     name: 'health',
            // },
            // {
            //     title: 'General Vaccination',
            //     name: 'generalvaccination',
            // },


        ]
    },
    {
        title: 'Utilities',
        name: 'utilities',
        children: [{
                title: 'Water',
                name: 'water',
            },
            {
                title: 'Electricity',
                name: 'electricity',
            },
            {
                title: 'CableTV/DTH',
                name: 'cabletvdth',
            },
            {
                title: 'Mobile',
                name: 'mobile',
            },
            {
                title: 'Landline/Broadband',
                name: 'landbroad',
            },
            {
                title: 'Gas',
                name: 'gas',
            },

        ]
    },

    {
        title: 'Leisure',
        name: 'leisure',
        children: [{
                title: 'Subscriptions',
                name: 'subscriptions',
            },
            {
                title: 'Occasion',
                name: 'occasion',
            },
            {
                title: 'Membership',
                name: 'membership',
            },
            {
                title: 'Travel',
                name: 'travel',
            },

        ]
    },
    {
        title: 'Liabilities',
        name: 'liabilities',
        children: [{
                title: 'Loan',
                name: 'loan',
            },
            {
                title: 'Credit Cards',
                name: 'creditcards',
            },
            {
                title: 'Pay Later',
                name: 'paylater',
            },
            {
                title: 'Paybles',
                name: 'payables',
            },
            {
                title: 'Credit Cards',
                name: 'creditcards',
            },

        ]
    },
    {
        title: 'Children',
        name: 'children',
        children: [{
                title: 'Schooling',
                name: 'school',
            },
            {
                title: 'Vaccination',
                name: 'vaccination',
            },
        ]
    },

    {
        title: 'Health',
        name: 'health',
        children: [{
                title: 'Insurance',
                name: 'insurance',
            },
            {
                title: 'OPD',
                name: 'opd',
            },
            {
                title: 'General Vaccination',
                name: 'generalvaccination',
            },
            {
                title: 'Cards',
                name: 'cards',
            },
        ]
    },
    {
        title: 'Cash Flow',
        name: 'cashflow',
        children: [{
                title: 'Transaction',
                name: 'transaction',
            },
            {
                title: 'Transfer',
                name: 'transfer',
            },

        ]
    },
    {
        title: 'Insurance',
        name: 'insurance',
        children: [{
                title: 'Life',
                name: 'life',
            },
            {
                title: 'General',
                name: 'general',
            },
        ]
    },
];


let data = [];

let listtemp = {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/reports/List.vue'),

    meta: {
        pageTitle: 'navigation.reports',
        breadcrumb: [{
            text: 'Reports',
            active: true,
        }, ],
    },
}
data.push(listtemp);
listtemp = {
    path: '/reports/networth',
    name: 'reports-networth',
    component: () => import('@/views/reports/networth/List.vue'),

    meta: {
        pageTitle: 'navigation.reports',
        breadcrumb: [{
                text: 'Reports',
                to: '/reports',
            },
            {
                text: 'Net Worth',
                active: true,
            },
        ],
    },
}
data.push(listtemp);

datalist.forEach(element => {

    let listtemp = {
        path: '/reports/' + element.name,
        name: 'reports-' + element.name,
        component: () => import('@/views/reports/' + element.name + '/List.vue'),
        meta: {
            pageTitle: 'navigation.' + element.name,
            breadcrumb: [{
                    text: 'Reports',
                    to: '/reports',
                },
                {
                    text: element.title,
                    active: true,
                }
            ],
        },
    }
    data.push(listtemp);
    element.children.forEach(elementx => {
        let listtemp = {
            path: '/reports/' + element.name + '/' + elementx.name,
            name: 'reports-' + element.name + '-' + elementx.name,
            component: () => import('@/views/reports/' + element.name + '/' + elementx.name + '/List.vue'),
            meta: {
                pageTitle: elementx.title,
                breadcrumb: [{
                        text: 'Reports',
                        to: '/reports',
                    },
                    {
                        text: element.title,
                        to: '/reports/' + element.name,
                    },
                    {
                        text: elementx.title,
                        active: true,
                    },
                ],
            },
        }
        data.push(listtemp);
    });
});
export default data
