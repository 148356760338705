var datalist = [];
let data = [];
let edittemp = {
    path: '/documents/',
    name: 'documents',
    component: () => import('@/views/documents/List.vue'),
}

data.push(edittemp)
var datalist = [

    {
        title: 'Salary Slips',
        name: 'employment-salaryslips',
        path: '/employment/salaryslips',
        filePath: '/employment/salaryslips',
    },
    {
        title: 'Offer Letters',
        name: 'employment-offerletters',
        path: '/employment/offerletters',
        filePath: '/employment/offerletters',
    },
    {
        title: 'Relieving Letters',
        name: 'employment-relievingletters',
        path: '/employment/relievingletters',
        filePath: '/employment/relievingletters',
    },
    {
        title: 'Form 16',
        name: 'employment-form',
        path: '/employment/form',
        filePath: '/employment/form',
    },
    {
        title: 'Other Documents',
        name: 'employment-otherdocuments',
        path: '/employment/otherdocuments',
        filePath: '/employment/otherdocuments',
    },

];
for (let i = 0; i < datalist.length; i++) {
    let listtemp = {
        path: datalist[i].path,
        name: datalist[i].name,
        component: () => import('@/views' + datalist[i].filePath + '/List.vue'),
        meta: {
            pageTitle:datalist[i].title,
            breadcrumb: [{
                    text: 'Employment',
                    to: '/documentsvault/employment',
                },
                {
                    text: datalist[i].title,
                    active: true,
                },
            ]
        },

    }
    data.push(listtemp);
    let addtemp = {
        path: datalist[i].path + '/add',
        name: datalist[i].name + '-add',
        component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
        meta: {
            pageTitle:datalist[i].title,
            breadcrumb: [{
                text: 'Employment',
                to: '/documentsvault/employment',
            }, {
                text: datalist[i].title,
                to: datalist[i].path,
            }, {
                text: 'Add',
                active: true,
            }, ],
        },
    }
    data.push(addtemp);
    let edittemp = {
        path: datalist[i].path + '/edit/:id',
        name: datalist[i].name + '-edit',
        component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
        meta: {
            pageTitle:datalist[i].title,
            breadcrumb: [{
                    text: 'Employment',
                    to: '/documentsvault/employment',
                }, {
                    text: datalist[i].title,
                    to: datalist[i].path,
                },  {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    }
    data.push(edittemp);
    let viewtemp = {
        path: datalist[i].path + '/view/:id',
        name: datalist[i].name + '-view',
        component: () => import('@/views' + datalist[i].filePath + '/View.vue'),
        meta: {
            pageTitle:datalist[i].title,
            breadcrumb: [{
                    text: 'Employment',
                    to: '/documentsvault/employment',
                }, {
                    text: datalist[i].title,
                    to: datalist[i].path,
                }, {
                    text: 'View',
                    active: true,
                },
            ],
        },
    }
    data.push(viewtemp);
}
export default data
