var datalist = [
  {
    title: 'navigation.water',
    name: 'utilities-water',
    path: '/utilities/water',
    filePath: '/utilities/water',
  },
  {
    title: 'navigation.electricity',
    name: 'utilities-electricity',
    path: '/utilities/electricity',
    filePath: '/utilities/electricity',
  },
  {
    title: 'navigation.cabletvdth',
    name: 'utilities-cabletvdth',
    path: '/utilities/cabletvdth',
    filePath: '/utilities/cabletvdth',
  },
  {
    title: 'navigation.mobile',
    name: 'utilities-mobile',
    path: '/utilities/mobile',
    filePath: '/utilities/mobile',
  },
  {
    title: 'navigation.landbroad',
    name: 'utilities-landbroad',
    path: '/utilities/landbroad',
    filePath: '/utilities/landbroad',
  },
  {
    title: 'navigation.gas',
    name: 'utilities-gas',
    path: '/utilities/gas',
    filePath: '/utilities/gas',
  },
];


let data = [];
for (let i = 0; i < datalist.length; i++) {
  let listtemp = {
    path: datalist[i].path,
    name: datalist[i].name,
    component: () => import('@/views' + datalist[i].filePath + '/List.vue'),
    meta: {
      pageTitle: 'navigation.utilities',
      breadcrumb: [
        {
            text: 'navigation.utilities',
            to: '/utilities/water',
        },
        {
            text: datalist[i].title,
            active: true,
        },
    ],
    },
  }
  data.push(listtemp);
  let addtemp = {
    path: datalist[i].path + '/add',
    name: datalist[i].name + '-add',
    component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
    meta: {
      pageTitle: 'navigation.utilities',
      breadcrumb: [
        {
            text: 'navigation.utilities',
            to: '/utilities',
        },
        {
            text: datalist[i].title,
            to: datalist[i].path,
        },{
        text: 'Add',
        active: true,
      }, ],
    },
  }
  data.push(addtemp);
  let edittemp = {
    path: datalist[i].path + '/edit/:id',
    name: datalist[i].name + '-edit',
    component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
    meta: {
      pageTitle: 'navigation.utilities',
      breadcrumb: [
        {
            text: 'navigation.utilities',
            to: '/utilities',
        },
        {
            text: datalist[i].title,
            to: datalist[i].path,
        },{
        text: 'Edit',
        active: true,
      }, ],
    },
  }
  data.push(edittemp);
  let viewtemp = {
    path: datalist[i].path + '/view/:id',
    name: datalist[i].name + '-view',
    component: () => import('@/views' + datalist[i].filePath + '/View.vue'),
    meta: {
      pageTitle: 'navigation.utilities',
      breadcrumb: [
        {
            text: 'navigation.utilities',
            to: '/utilities',
        },
        {
            text: datalist[i].title,
            to: datalist[i].path,
        },{
        text: 'View',
        active: true,
      }, ],
    },
  }
  data.push(viewtemp);


}
export default data
