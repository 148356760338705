var datalist = [

    {
        title: 'navigation.insurance',
        name: 'health-insurance',
        path: '/health/insurance',
        filePath: '/health/insurance',
    },
    {
        title: 'navigation.opd',
        name: 'health-opd',
        path: '/health/opd',
        filePath: '/health/opd',
    },

    {
        title: 'navigation.cards',
        name: 'health-cards',
        path: '/health/cards',
        filePath: '/health/cards',
    },
    {
        title: 'navigation.generalvaccination',
        name: 'health-generalvaccination',
        path: '/health/generalvaccination',
        filePath: '/health/generalvaccination',
    },
];

let data = [];
for (let i = 0; i < datalist.length; i++) {
    let listtemp = {
        path: datalist[i].path,
        name: datalist[i].name,
        component: () => import('@/views' + datalist[i].filePath + '/List.vue'),
        meta: {
            pageTitle: 'navigation.health',
            breadcrumb: [{
                    text: 'navigation.health',
                    to: '/health/insurance',
                },
                {
                    text: datalist[i].title,
                    active: true,
                },
            ],
        },
    }
    data.push(listtemp);
    let addtemp = {
        path: datalist[i].path + '/add',
        name: datalist[i].name + '-add',
        component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
        meta: {
            pageTitle: 'navigation.health',
            breadcrumb: [{
                    text: 'navigation.health',
                    to: '/health',
                },
                {
                    text: datalist[i].title,
                    to: datalist[i].path,
                }, {
                    text: 'Add',
                    active: true,
                },
            ],
        },
    }
    data.push(addtemp);
    let edittemp = {
        path: datalist[i].path + '/edit/:id',
        name: datalist[i].name + '-edit',
        component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
        meta: {
            pageTitle: 'navigation.health',
            breadcrumb: [{
                    text: 'navigation.health',
                    to: '/health',
                },
                {
                    text: datalist[i].title,
                    to: datalist[i].path,
                }, {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    }
    data.push(edittemp);
    let viewtemp = {
        path: datalist[i].path + '/view/:id',
        name: datalist[i].name + '-view',
        component: () => import('@/views' + datalist[i].filePath + '/View.vue'),
        meta: {
            pageTitle: 'navigation.health',
            breadcrumb: [{
                    text: 'navigation.health',
                    to: '/health',
                },
                {
                    text: datalist[i].title,
                    to: datalist[i].path,
                }, {
                    text: 'View',
                    active: true,
                },
            ],
        },
    }
    data.push(viewtemp);


}
export default data
