
var datalist = [];
let data = [];
let edittemp = {
  path: '/settings/profile/',
  name: 'settings-profile',
  component: () => import('@/views/settings/profile/List.vue'),
  meta: {
    pageTitle: 'settings.settings',
  },
}
data.push(edittemp)
edittemp = {
  path: '/settings/profile/edit',
  name: 'settings-profile-edit',
  component: () => import('@/views/settings/profile/Manage.vue'),
  meta: {
    pageTitle: 'settings.settings',
    breadcrumb: [{
      text: 'meta.edit',
      active: true,
    }, ],
  },
}
data.push(edittemp)
edittemp = {
    path: '/pricing',
    name: 'settings-pricing',
    component: () => import('@/views/pages/pricing/Pricing.vue'),
    meta: {
      pageTitle: 'settings.settings',
      breadcrumb: [{
        text: 'meta.edit',
        active: true,
      }, ],
    },
  }
  data.push(edittemp)

var datalist = [
  {
    title: 'Family Members',
    name: 'settings-familymembers',
    path: '/settings/familymembers',
    filePath: '/settings/familymember',
  },
  {
    title: 'Childrens',
    name: 'settings-children',
    path: '/settings/children',
    filePath: '/settings/children',
  },
  {
    title: 'Real Estate ',
    name: 'settings-realestate',
    path: '/settings/realestate',
    filePath: '/settings/realestate',
  },
  {
    title: 'Nominee ',
    name: 'settings-nominee',
    path: '/settings/nominee',
    filePath: '/settings/nominee',

  },



];

for (let i = 0; i < datalist.length; i++) {
  let listtemp = {
    path: datalist[i].path,
    name: datalist[i].name,
    component: () => import('@/views' + datalist[i].filePath + '/List.vue'),
    meta: {
      pageTitle: 'navigation.settings',
    },
  }
  data.push(listtemp);
  let addtemp = {
    path: datalist[i].path + '/add',
    name: datalist[i].name + '-add',
    component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
    meta: {
      pageTitle: 'navigation.settings',
      breadcrumb: [{
        text: 'Add',
        active: true,
      }, ],
    },
  }
  data.push(addtemp);
  let edittemp = {
    path: datalist[i].path + '/edit/:id',
    name: datalist[i].name + '-edit',
    component: () => import('@/views' + datalist[i].filePath + '/Manage.vue'),
    meta: {
      pageTitle: 'navigation.settings',
      breadcrumb: [{
        text: 'Edit',
        active: true,
      }, ],
    },
  }
  data.push(edittemp);
  let viewtemp = {
    path: datalist[i].path + '/view/:id',
    name: datalist[i].name + '-view',
    component: () => import('@/views' + datalist[i].filePath + '/View.vue'),
    meta: {
      pageTitle: 'navigation.settings',
      breadcrumb: [{
        text: 'View',
        active: true,
      }, ],
    },
  }
  data.push(viewtemp);
}
export default data
